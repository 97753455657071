
import React from "react";
// import Link from "gatsby-link";
import PropTypes from "prop-types";
// import Media from "react-media";
import Seo from "./seo.js";
import Header from "./header.js";
import Navigation from "./navigation.js";
import Sidebar from "./sidebar.js";
import Footer from "./footer.js";
import "../styles/breakpoints.less"
import "../styles/colors.less"
import "../styles/background-colors.less"
import "../styles/blog-typography.less"
import "../styles/flex.less"
import "../styles/blogcontent.less"

const TemplateWrapper = content => {
  const { share } = content;

  return (
  <div className="wrapper">
    <Seo share={share} />
    <div className="flex-wrapper"> 
      {/* <Header headerImages = {content.headerImages} /> */}
      <Header />
      {/* <Media query={{ maxWidth: 799 }}>
        {matches =>
          matches ? (
            <div
              // style={{
              //   margin: "0 auto",
              //   // maxWidth: 980,
              //   display: "flex",
              //   flexDirection: "row",
              //   justifyContent: "space-between",
              //   height: "100%",
              //   padding: "25px"
              // }}
            >
            <div className="cst-blog-content">
              {content.children}
            </div>
            </div>
          ) : ( */}
            <div className="flex-mid-tier">
              <div className="flex-left" >
                <Navigation />
                <Sidebar
                  title="CyberSym Blogs"
                  content={
                    <ul className="menu" style={{marginBottom: "0"}}>
                      <li className="li"><a href="http://blogs.cybersym.com" className="a"><span>CyberSym Blogs Home</span></a></li>
                      <li className="li"><a href="http://blogs.cybersym.com/science-society" className="a"><span>Science &amp; Society</span></a></li>
                      <li className="li"><a href="http://blogs.cybersym.com/tech-intersection"className="a"><span>Technology Intersection</span></a></li>
                      <li className="li"><a href="http://blogs.cybersym.com/ultrarunning"className="a"><span>Ultrarunning Edge</span></a></li>
                    </ul>		
                  }
                />
              </div>
              <div className="flex-content cst-blog-content">
                <div style={{textAlign: "center", float: "center", display: "block"}}>
                </div>
                {content.children}
              </div>
            </div>
          {/* )
        }
      </Media>  */}
      <Footer />
    </div>
  </div>
  )
};  

TemplateWrapper.propTypes = {
    children: PropTypes.array
};

export default TemplateWrapper;